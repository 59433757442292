import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "memsource-plugin"
    }}>{`Memsource Plugin`}</h1>
    <h2 {...{
      "id": "why-install-memsource-plugin"
    }}>{`Why Install Memsource Plugin?`}</h2>
    <p>{`Translating strings of text to other languages can break UI components within a layout, which takes
up valuable development resources to fix. For example, an English text string in a button component
can expand up to 30% when it translates to other European languages. With the Figma Memsource
Plugin, a TMS (Translation Management System) connector, we can introduce a localization process to
Workday designers and help lessen the burden of fixing layout problems that resulted from language
string translation.`}</p>
    <p>{`Sample English design:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/251872af22fde4d4de959a49885604cc/c946b/memsource_english_project.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.2972972972973%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB4UlEQVR42p1U2XKcMBDk/78vL/sSO+t1arl1ARJIdKZlcIGzTtlRVRfDCJrpnkHF77rFj58vuN5e8Vo1uDy9oFcat+cLuqbEteokd0VZVei0xeX5hqppoa2DMvYvFMpNuBuPcfJg3Aom7zGoEpNTcu/RWslNE+wwoZF4GD9HAVkxLpiXBW9xhA8B67oirkBI+NbKhKyO7Fx+nuEkXlNEO634pROSkB/XuoF5Yj3kipSSSAwIUmGYWWnMsZecGjz0NMOIX23bohF0AqpZH3wgE3KzlGb0ysC4IaPXBnfJ1W2Xveu6DkqpjL7vs0V82YpLZsaJvKBXdhihpUNB5C7iIRFj2uKEYRgQxNdZ9vdFqd0QUFuPoyPFksR8kU3iz2CthdYazrl8n2XKdZT70dn3XCa82yTz5eTh8wa9pTxWTSIvo3RqTP6QE38tFnq6fbzQYYVfEp84VUVCjg9ls7qmaXJjdtmUbMXvmO2JuVF8J3vICsZxPFXATRKRgJLrus7X477dLNjx1pQHrd+Rm7MRH6vbCdmsh4P9r7U3hX4aY06EH339OqFIIxlB8ncfU/o/Qg40PWRjGKdtzI4WfIvwXpaZkBI54Hs+hDl7TOz/9ZcIKXXZTqNjnocI/zIeLPsc/gHqCTS+CpM6TAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot of a English page.",
            "title": "Screenshot of a English page.",
            "src": "/static/251872af22fde4d4de959a49885604cc/50383/memsource_english_project.png",
            "srcSet": ["/static/251872af22fde4d4de959a49885604cc/1efb2/memsource_english_project.png 370w", "/static/251872af22fde4d4de959a49885604cc/50383/memsource_english_project.png 740w", "/static/251872af22fde4d4de959a49885604cc/c946b/memsource_english_project.png 805w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`German translated design:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d8f4322fb8f1df9c777724602e9d9051/c946b/memsource_german_translated_project.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "94.05405405405405%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAACP0lEQVR42p1Ua5OaMBTl//+1tvvJTh/rtIuWBQXlkRBIApzeE8Cq253tFudOYhJvzgujqqqw3W7BkY9zHqo1GMcRputh5ft7nijLMjw8fEK826Hve9R1Ay0Nm7aDNj3atoVSCp3s9bZH0zRoTSdz+9eKHvcpPnz7iec0Q5wV+BonqBqF/fePqIoUX3YHbLZPqGUtLxt8/vELh/wkZzTKWr2oqO0tjBswkKJ1sH6E8x69qeF6g856dELbOitzKTlrnXsdIXmTKinxsbLIH4T1AWj9JLMJ0zTNIq3jaxpyn+KbpWF/1TDVEx7LEeNdDzZnjUtNVxX5YaZAN01H2E5EF1PECC2GeO8kATVoHqsoCkmCex0hqT7t9ijKanZXtzjL/Dk7IBGjOtF4s9mEaK2ODwKCoCvpe7K3KkTcLM5laDQMY6hRONIk72ez6rqeo9N1odlKu9Q9CmX+6BsQiuikHW690oaNuMa51jo05LjS5TkjiI1WmOTspWFSD6iVbIhu19rzTSnP59AghLltwwVMQbhYPlxn0UgC4OWRspNkzws9PzsWQoIwt8tBUj4ejyjLUvQtLxSV0uESnmF2CWLWUJwLji5iszgvTqeQANIlkvvokBWb3JgyLZtYGt3UoikR5nkeLqYxa0NqOt0FPXrr3yO4KTRJeW12jfBFDv+l4VHQJUkSUBLVur5G6F0NqVGapojjOIwXeRbT/gthviAk9et1OhvyysissXkToRyiy4zV/UVaNNTyR9yIDH55MX4D1j/OIonLHMIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot of a translated page.",
            "title": "Screenshot of a translated page.",
            "src": "/static/d8f4322fb8f1df9c777724602e9d9051/50383/memsource_german_translated_project.png",
            "srcSet": ["/static/d8f4322fb8f1df9c777724602e9d9051/1efb2/memsource_german_translated_project.png 370w", "/static/d8f4322fb8f1df9c777724602e9d9051/50383/memsource_german_translated_project.png 740w", "/static/d8f4322fb8f1df9c777724602e9d9051/c946b/memsource_german_translated_project.png 805w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "get-memsource-account"
    }}>{`Get Memsource Account`}</h2>
    <p>{`Join the Slack channel `}<em parentName="p">{`#figma-memsource-plugin`}</em>{`. Click `}<em parentName="p">{`Attachments & shortcuts`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "507px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0d1655866ced89261082d9f9735b04c6/3ebb1/shortcut.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.18918918918919%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA50lEQVR42m2R0W6DIBSGfQBXo3YXUywCggOp1rquplmyq73/I/2D0yxNOi++nIQTPn7OSbg0YAcFxjs07QMueyjjILWj+mAIZ3aT+iCRMNbAWotOa0ipoI2B1gb7/SuMG9G7CXY4UXXHGX5a0PX+iQHGjhQkSdMURVEgj+Q5sixDWZZ42e2g332QnEkmunvaLURnSUzCiitUjUDdcFSM463mIboITUWvRpmxR7q0xZ8wjoKE6/cP/HnFvKyYAjHR5fqF5XLDR6jj/Enp/n/Tk4RG4u8jIWEblsKFRlzOM63qia3eFnEpv+pRqwIOaIiGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot of shortcut.",
            "title": "Screenshot of shortcut.",
            "src": "/static/0d1655866ced89261082d9f9735b04c6/3ebb1/shortcut.png",
            "srcSet": ["/static/0d1655866ced89261082d9f9735b04c6/1efb2/shortcut.png 370w", "/static/0d1655866ced89261082d9f9735b04c6/3ebb1/shortcut.png 507w"],
            "sizes": "(max-width: 507px) 100vw, 507px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`and select `}<em parentName="p">{`Get Memsource Account`}</em>{` from the list.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "364px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/886583a5a75f83eb9f6d4572e1fd5458/e45a9/workflow.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.61538461538461%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVR42oWSy2/TQBDG/RcgDg0SoiSx14/Y8TN+xY7jhpRWgIR4XKDAkZa2HHquhDggFU4UhFASuPN3fsyOW6QGLA6/fLOb2dnZb6wI04FueTAsl3TYYA6vxgTnXWgbmmFDEaaNm84YfjpDMp4SWwjiEv5ojFE+RZCUiEmHQYqusKFR0TZUw4Fy/ckZHp79wu6HBcTrH3CPfzLmwQrmmwb7qFkb+0t0Xn7Fxos19r5g49V3dB6/h3Lt6Ufsn5zj2eknOkAFDxcIjlcI364QHC0xPFjAO1zy2iXt7J3/zfPPdNE33Hj0Dor05Zbh4bbuc8s9QehSbaavN3vdi7VgH/8Ne6jqA/hBjGJSw3J89FQTqrDQJwQlCLqk0QbtPyh9MUCSTZAVNJC8wigtWZN8QlN2WzpxWuGCo7RAFNNUk4IJooxVHu5Sxz3K6WlWE5OuF1F1m+3igjKhqGbIixrldI56tsM6qefIy/qPyv072/dQbW1f6VLG8537/DJZWJE/thti4ASMPQzZS45p3xh4MG0Plu3zfyat1z9wmSv3ZaxsdjXc3X2AijqRpnthwgc9GpQcjPTSocLSZ8eN2A6VLNCokUtk3uWestkTmNJzMnqyvNkLU+osguvH/KSIvJTrYJTDp2LZuOKntQ3lN0Gsf43zG4ZjAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot of workflow.",
            "title": "Screenshot of workflow.",
            "src": "/static/886583a5a75f83eb9f6d4572e1fd5458/e45a9/workflow.png",
            "srcSet": ["/static/886583a5a75f83eb9f6d4572e1fd5458/e45a9/workflow.png 364w"],
            "sizes": "(max-width: 364px) 100vw, 364px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`You should receive a direct message from Slackbot. After you fill in the form, you will receive the
memsource account information.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "477px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4eb8ecb70b5612183584ecb1fb65131c/d743b/slackbot.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.24324324324324%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABPklEQVR42o1QSU7DQBD0EzgRJ7Hj3bE942XGexazSwi4cOBM3oL4DA/hC3yn6BkpEiCBONRUqzWq6irj9vUF8myPcZzQ9FuIptcYt5NGJTt0ww5Nt0G4ZvDjTLO5dDBbrGASFJ/Obb0zPt7fcP90CSG3WqAfd2hJoNucY5yuUcgRLZl1A5mJRhsoQ0lIeQVWSmR5pfd+lMIYkggs56jqAawQKGWPthZ4vstweOA4ED9ecIRlD3e8gccEXD9GQFd6YaKhhOI0h+NHME5mNkXgJNQiYSW5CeRFiX3HMPUMVxuOQTIEaYF5nMN0QopGMSmeaTk65jH+3HJhqEd1wul05bLOCh0lTEuCYoGI5nWWw7I9LOj/QvEvMNRjOYEWLURLwjUCKv5Y9tfi/xL6Jmi7oe5BRY4Sruflyv8B71+Cn5hU0BMt82FYAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot of a translated page.",
            "title": "Screenshot of a translated page.",
            "src": "/static/4eb8ecb70b5612183584ecb1fb65131c/d743b/slackbot.png",
            "srcSet": ["/static/4eb8ecb70b5612183584ecb1fb65131c/1efb2/slackbot.png 370w", "/static/4eb8ecb70b5612183584ecb1fb65131c/d743b/slackbot.png 477w"],
            "sizes": "(max-width: 477px) 100vw, 477px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "install-plugin"
    }}>{`Install Plugin`}</h2>
    <p>{`Log in to Figma from the desktop app on a web browser, navigate to “Explore Community” at
`}<a parentName="p" {...{
        "href": "https://www.figma.com/community/explore"
      }}>{`https://www.figma.com/community/explore`}</a>{`. Enter “Memsource” in search bar. Select the “Plugins” tab
and install the Memsource plugin from the search result. You can find more details about the plugin
and version history when you click on the plugin.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "674px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/1daa6e9e70a77f458c0ae2263f9dcc25/fdaf8/memsource_figma_community.png",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "31.62162162162162%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAsUlEQVR42pVRWwrCMBDM3TyHp/BgfnkKL6B+VCr4IVhImub9GLPBligIdWGYySQ7bBIWQkBKCcYYDMMA5xzIIybfew9rbdVrivkYEWJCfDfHss45f2BtWA1MNE0JaasN+17/whJ4NsAjAMIF6GmCLldvD/xbbNcBmxOwv0949h2u/Q2cc4zjCCllBWkhxKJnkEestV6GYAcObC/AkRcjOhjr6gcppSrPoKaWaX/W7Ru/ADv11PJeECiEAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "memsource figma community",
          "title": "memsource figma community",
          "src": "/static/1daa6e9e70a77f458c0ae2263f9dcc25/fdaf8/memsource_figma_community.png",
          "srcSet": ["/static/1daa6e9e70a77f458c0ae2263f9dcc25/1efb2/memsource_figma_community.png 370w", "/static/1daa6e9e70a77f458c0ae2263f9dcc25/fdaf8/memsource_figma_community.png 674w"],
          "sizes": "(max-width: 674px) 100vw, 674px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
  `}</a>{`
    `}</span>
    <p>{`Open the Figma desktop app or in a web browser, then locate the Memsource plugin in the plugins
list.`}</p>
    <p>{`Refresh the Figma file or tab if you're unable to find the plugin.`}</p>
    <h2 {...{
      "id": "translation-guide"
    }}>{`Translation Guide`}</h2>
    <p>{`Open an English project and select the page you want to translate.`}</p>
    <h3 {...{
      "id": "connect-to-memsource"
    }}>{`Connect to Memsource`}</h3>
    <p>{`Select `}<em parentName="p">{`Plugins->Memsource->Connect to Memsource...`}</em>{`, and login with your Memsource credentials. You
only need to login once since the plugin will remember your credentials.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "497px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/9fffcc01c6a0441508af5c9af3a62e75/15d25/memsource_login.png",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.29729729729729%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABuElEQVR42p1T207bQBD156E+wEPVf4Cvqiioj+0TohQopRAhgYSgJbEd2+trfF9fIKA8RDrMeKFFJBUES8c7e6w9M2dmrR2PATuvUCQxpJR46zOdTjGZTKDlZY20qDqMMok4Kymu/6Ko2oWgDSwPW/vH6J1dYv/oFN8Oetj5eYLvhyc4Or1AWjaIyQEjoQQMTvw/aGFSQAQxvCiDN8rUGqUI4hwurfxNBEkHh2JG5+QhyXNofNhwAljeCP2hC674jyFg05555q5MAd32aVVxmJYY0eHnUIJUlU4iA9tDlBZksYYlfPQNC8KP0NzcoWrHqJqxWgllfT0D7l8nKMKky246IdksOtInTvhhN6D6+hayuXkRLKoEqTfnVyZ+6za4n0zaboghV0x95OknxOU0nHmVzVTIPeS+cK98EmDSsASMoYMB2e7rQ+gUh3E21+5Mhfx6HPnTaT1eEYbaKz7KngwiU5y6v/Kf4GvAAmy/qVtUskFdMZTNVLZIHu6r9lqxjKavBymWN/bw/vMPvPu0i7WvPRSyhvAC+FGMnP+URQVX1nfwYXMPSx+3sfrlF1K6Cbppw3H9zsE9FXXABNgDAxIAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "memsource login",
          "title": "memsource login",
          "src": "/static/9fffcc01c6a0441508af5c9af3a62e75/15d25/memsource_login.png",
          "srcSet": ["/static/9fffcc01c6a0441508af5c9af3a62e75/1efb2/memsource_login.png 370w", "/static/9fffcc01c6a0441508af5c9af3a62e75/15d25/memsource_login.png 497w"],
          "sizes": "(max-width: 497px) 100vw, 497px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
  `}</a>{`
    `}</span>
    <h3 {...{
      "id": "get-machine-translation"
    }}>{`Get Machine Translation`}</h3>
    <h4 {...{
      "id": "submit-page-for-translation"
    }}>{`Submit page for translation`}</h4>
    <p>{`Select `}<em parentName="p">{`Plugins->Memsource->Submit page for translation...`}</em>{` Select `}<em parentName="p">{`New from template`}</em>{`, choose
`}<em parentName="p">{`Figma Template`}</em>{` from the list and click next. If you submitted this page before, you could select
Update existing. However, you will only be able to select the target language you selected when you
first submit the page.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "502px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/680a78e162af7a11bd83ffb9aeb0ae11/eea79/memsource_select_template.png",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.83783783783784%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACUUlEQVR42p1STW/aQBD1uff+4V6qXnvoT6jUQ9VrD03T0gRCSDBg82GwjW3s9TcGAoFAqteZNaZUqlSplp5md3b9Zua9VdrFI+ZxhDhOsNvt8L/f4XDAcrmE4m322G62eNg84ml/wP75J/aHZzwROPK+Wh+qs311Xu63uz3WxJHlBZRGq4Nas4P6nYaLq1taq/hBqN9rlOvh63ULTbWP2o2KL7Um5Xu4uu3gpq3hW+Mel/U79CcOonSBmYih8EYf2RhQ7Bs2NFp3hyb047rTn8gz3qv6GN2Bif54KsG5Ht21XIEoOxL6UQ4/JkQZAopxvkQ8XxGWSPIFEopRVlB+gZRzhPiYj7Pf50zohwmU3tCSHeoji6raJWk6l5iF6R9w/AjTWSjhRyncIJY5RlqsSkJNEvEIDlQaz7ZdfOpZ+KxZMMc0rmGiqw1gTGzMl2vCwykWq7VcFwRZwA+huEECVySYUpWJG8Cjqp2pgO6GcKkTrm57QnYVUtcMwTGhmJSRczyZ1HBkeVJcj0bitiPWijVLyx9EkkOwDJQL4kzuA9L7XMcKknBoumT7FCZ1x25xLA2aSwLnTKdKM4t1FOVk3F1pSlES8pNgUgbryfDkxUKOYToBFREYT30YBIsKztgUIrcpz2bxPW5AErLd5+BnI5L5CZxj8pBlILj0zJpmgIbpwxQkU378j58NEwb00wlxGcVfMKMuVosVPt4bePn6A168eo83FyrWeY5Wu0tkERmUQwkrp/4BNobN0mnMd99VvL1s49pwkBKJR2SCno2IEvwCBwMGbbqFScEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "memsource select template",
          "title": "memsource select template",
          "src": "/static/680a78e162af7a11bd83ffb9aeb0ae11/eea79/memsource_select_template.png",
          "srcSet": ["/static/680a78e162af7a11bd83ffb9aeb0ae11/1efb2/memsource_select_template.png 370w", "/static/680a78e162af7a11bd83ffb9aeb0ae11/eea79/memsource_select_template.png 502w"],
          "sizes": "(max-width: 502px) 100vw, 502px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
  `}</a>{`
    `}</span>
    <p>{`Select the target languages and click submit. We recommend selecting at least one European language,
one East Asian language, and one bi-directional language for optimal coverage.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "493px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/f57c25b8f0cc2abf6de18bfc60df5be8/f88d0/memsource_language_select.png",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.02702702702703%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACDUlEQVR42p1SS24TQRSc23AG7sCWA3AIjsCWfTaITcQGCSISCWOC7ACKkkhBGBLHHk889tjz7xl7/jayiuoeO+FrJEYqven3uuu9ri6tmQCWH8KzbYRC4H+/1WqFsiyheUEMiSCawxczxhncML7599d5jzkvZC2I1DqMk98gObRPF328ah6heXSCt+9Psfe6hTftYxwcfsBeo6Xw7uMZGq1jBbm30zXgcIiJG2LqiRu4fgTNZ1dBdj8ixBw2C3ISecD2Ijjc5MrJwpnK11PGqv4jZG4w9aHtdwzsfzHgOZ4aOS8WSLISSV4iVajq9RbM0gJVucTuaQ+a4YTojh1cdHUYowkyEuTlgljHoo4Z498gGy8WSxz2xtDkdQMKfjUYomeY0IdjxhEMc4LR1MU1mzh0wb9IZeN5mkOT2ji+gH49hm5aJLRIbmJAQjmxwZyMcoqthGwYxiltI2qb9EloWrYqJlmx1u9Wp21kNWGFaEZC+bKeurKJkL6rlt+QVwsU7FhUt6h15bWJVOq2xiZfVEtE8wyafPKx7aFzqWM0cSHY5U+m3Rg3STIsixJlzpclAuYFiaJ5qm6q2fTZ0HLwuTvA1/6Q5D5sX/xkWAmLJo5J+PzsEvefHODBbgMPX7TV3hGlMidTSPkUocTG9Zv1r7BYSznFTvscdx49xd3Hz3Bv5yXPBehe9dHTDSXdd2aKyZmJaM1YAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "memsource language select",
          "title": "memsource language select",
          "src": "/static/f57c25b8f0cc2abf6de18bfc60df5be8/f88d0/memsource_language_select.png",
          "srcSet": ["/static/f57c25b8f0cc2abf6de18bfc60df5be8/1efb2/memsource_language_select.png 370w", "/static/f57c25b8f0cc2abf6de18bfc60df5be8/f88d0/memsource_language_select.png 493w"],
          "sizes": "(max-width: 493px) 100vw, 493px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
  `}</a>{`
    `}</span>
    <h4 {...{
      "id": "manage-strings-to-translate-optional"
    }}>{`Manage strings to translate (Optional)`}</h4>
    <p>{`All the strings on the selected page will be submitted for translation by default. However, you can
unselect the strings you don't want to translate. Examples of items that you may choose not to
translate should include proper product names.`}</p>
    <p>{`Select `}<em parentName="p">{`Plugins->Memsource->Manage strings to translate...`}</em></p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "740px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/2b945a75076adb66026aa7139817803a/2ad15/memsource_manage_strings.png",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "73.78378378378379%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACZklEQVR42n1T227TUBD0l/JjIHhASAje+ADeKkrplSap48TJ8f3u2I5zaaJ2mD2u01AukUZ7dOIzO7uza6j2HnVVYb/f4/j3+Ph4iD0eHh7+i91uB+PneIbzmztcDy0MTBtXgzEubk3cjCa4ZJT7S94N+F2SV0iLCkkhsX5GWev//CiHMZm5sGwHI2sGc6ow9yK4UUpkUEECN0xhu6GOQVLw/xjKTzBjnPHbZ8TwwgyG40dUYWLuBrxIEGcL5IvlIXO2aBBlJUYTBWvuY2y7GFpzTJ1AJxL05yChQp9STSqczD1Mla9h84OyXh6Qk1QxccgHck7yBYpqiUXTompWOq7XG1w5MYxPF2PYlLxab7FcbTRanleb+wOERMoN0xI+SWOKiFiJrobEgoqkcdnAeHNyiyF7tz4iEAhpj5iKpE9zv+ufF+cdIe9LUVq3GvVyBeP99xECZt5sd7+RHENcFVM0aJiY5dE06XOvsBRSqjQ+nI3gpwW29/t/EspDIVGikNF5cjyvGm2aoOspFb77NsA552ymPDTt5q+E0itNGHTj4nI8jksVaHNqUfjjDpYKsGzX2FDly/51CiutSsrUUfew1IkKkhR9yZrw7A63nKs4zTnpqb58SSo9FFOkZD3YVOrHBYmZgEncKNcG6ZLfsuRLrtnQnHL+fJbS/Oly1rncb44Kuz4mNLOtaAzJEva5Epe/XE9hOSEfhHxYcjueG52VHWREfJoQPEHOMtwjL8Hr0xFO7YCkpVZpXFgKA+7xUHaZGyNbojeG0Wc5GiwtYN96yF3G5CdjhVcfv+LzuQnPcaG8EL8Acvpe75ikOt0AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "memsource manage strings",
          "title": "memsource manage strings",
          "src": "/static/2b945a75076adb66026aa7139817803a/50383/memsource_manage_strings.png",
          "srcSet": ["/static/2b945a75076adb66026aa7139817803a/1efb2/memsource_manage_strings.png 370w", "/static/2b945a75076adb66026aa7139817803a/50383/memsource_manage_strings.png 740w", "/static/2b945a75076adb66026aa7139817803a/2ad15/memsource_manage_strings.png 801w"],
          "sizes": "(max-width: 740px) 100vw, 740px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
  `}</a>{`
    `}</span>
    <p>{`You can select frames/groups before clicking Manage strings to translate. Then the string list will
only include the strings from those frames/groups.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "740px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/675c764472af2b41970825d925420df7/1ac29/memsource_manage_strings_frames.png",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.2972972972973%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABr0lEQVR42pWS3Y7TMBCF8648Ha/ABVwgroFlC022TdL820kcp85fS7uHY7dbqhVCWkufxrKTM2dm7FVqwDgvOJ/PsEv0A4JdjryUKGWLrKyRVzWjdNGe9WaCGWbsiRn/0vFfbxhGGGOwLIsTrBuF76sAj+sNVv4WQZg61pudw+c+LRvEWUWEi9GVpJBWcI+6lpjn2Qmq3uCBYj+3MeJCIJMKQu0hOwNBgjjHOsrwi8I+Y5gLbFMryFjU8GaWq/ueJYxOMKgUvqwjrIIIj09WVFJoj4blSG2QihYJk+SNRl5rlKpH1V5I6g6eT4GHpESoRsjDMz6HJT5881EVFVI6nKYFy+E3W3LENB9QWEHr3PW2Qd1qtEzY6UsVnlIdS25wOB6dQ5/9+fhj4wZjM6Z3JLJDyHtbWnjF7mObRChEVWt7aKC1xul0coINsz3R8Y4uEjrI+PE9uS33Fbc74k3TdBuIXZq9KpitpKOCH78VD69WxylntP4WkfL/ggOtXxze8y+hiueC0y0YpX1a5Cb4fI12YmFaIs7F7eHafX7t0Qu2Z4LYAb57/wlf/RDxLsMfNVM4nKNvpCgAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "memsource manage strings frames",
          "title": "memsource manage strings frames",
          "src": "/static/675c764472af2b41970825d925420df7/50383/memsource_manage_strings_frames.png",
          "srcSet": ["/static/675c764472af2b41970825d925420df7/1efb2/memsource_manage_strings_frames.png 370w", "/static/675c764472af2b41970825d925420df7/50383/memsource_manage_strings_frames.png 740w", "/static/675c764472af2b41970825d925420df7/1ac29/memsource_manage_strings_frames.png 1022w"],
          "sizes": "(max-width: 740px) 100vw, 740px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
  `}</a>{`
    `}</span>
    <h4 {...{
      "id": "check-translation-status"
    }}>{`Check translation status`}</h4>
    <p>{`Select `}<em parentName="p">{`Plugins->Memsource->Check translation status…`}</em>{` The status `}<strong parentName="p">{`Delivered`}</strong>{` means the machine
translation is done. `}<strong parentName="p">{`In progress (0%)`}</strong>{` means the translation is not finished or something goes
wrong. For a project with 200 strings, the translation time should be around 5 seconds. Please refer
to the “Question/Bug report” section to get support if the process takes too long.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "502px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/56292bfc2dbf98368856843ad6c29647/eea79/memsource_status.png",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.83783783783784%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVR42p2S247aQAyG8/7vUlXai6pXVbcVpdsSltMCIZAEcg4kIeRESNBfe9gDlK6EGumTxxP7H489UjcuEPse/CBAVVX436+uayRJAsnMDyiLArssx746oG6OONQNQfZAtmmEX9G6OR7J1sjyPfLikqLcI4xiSD8eHtHqDCAPpvjalnHf7uJnd4TW776w97T3jWPI//SlhV+9Mdx1DMsPYT9jeRvMlw5WtkeC8hDfKYEDO/0J2uS35RG6QwUjRUNvNEN/PMd0sUTvaUZ2JQSdIDojhGEHWDk+JNWwoBo2VN2CtnLB/kwzYblrhNvsRJIhCBNstqmwLPg3/mYLkwVT6gcvuNw99afYHwQl4a0jKFSZtrKpxyXS/ERGPbuirOD4G0ghnbpY2iIxojX/3GUFcgpw6SoTVcecqk5oL6V/7/IiuE1z6KYDZW4g3mWiOhYtq1OFJ0FTVMaHvAvluSzI99dNV8DT8qgXL/A/niI3/nz/3yTU9wAST2hI0xxMFmL0nGyfPQmb/bM9y7vkLTY6DeX89OvncI3PE33Gu4iP3wRvhSvRnPUrhrsRovxkGOtWQb4yJ6pWgA8dBXePKj7KM3weLLAOYzyNp9CXJsWENBQKvgWPMKlnfc3GgGA7Xrpispbrw/YCOF6AP3HKFg76EqPoAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "memsource status",
          "title": "memsource status",
          "src": "/static/56292bfc2dbf98368856843ad6c29647/eea79/memsource_status.png",
          "srcSet": ["/static/56292bfc2dbf98368856843ad6c29647/1efb2/memsource_status.png 370w", "/static/56292bfc2dbf98368856843ad6c29647/eea79/memsource_status.png 502w"],
          "sizes": "(max-width: 502px) 100vw, 502px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
  `}</a>{`
    `}</span>
    <p>{`Select the language you want and click retrieve. You should be able to see the translated page in
the Pages section.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "381px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/9b54dc75871394787d258939732de230/2add2/memsource_translated_pages.png",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.432432432432435%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+UlEQVR42p2SCWrFMAxEc/+z5RKB7Juz74l+n1qH0oU6FQhZYI3HM/LWdZUgCKRtWyGu6/qWLrEsi5znKd5xHNI0jWzb9uvlnx75mswrIAOw9H1f8jyXoiikrms9Z1kmaZrKvu9/AhI3IAMMD8Mg4zjKPM9ap2nS6vpt5jxQ+77/YFVIHMfa/ycg4L1rdL6JOihQ13VaYYYUT0IZWg3aJhFjajXIGKMJuKspN0N0YzgMQ/1uFEWSJIn2GAJblwQHQz1QaazDtgJWlqW+6pJ8Fx9ulwEB4PPaVFX1SEfV0O4PDdtOtS/a7X/sMltuFxkdOVPR0jJ0MQWGL1q8qXFLt47iAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "memsource translated pages",
          "title": "memsource translated pages",
          "src": "/static/9b54dc75871394787d258939732de230/2add2/memsource_translated_pages.png",
          "srcSet": ["/static/9b54dc75871394787d258939732de230/1efb2/memsource_translated_pages.png 370w", "/static/9b54dc75871394787d258939732de230/2add2/memsource_translated_pages.png 381w"],
          "sizes": "(max-width: 381px) 100vw, 381px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
  `}</a>{`
    `}</span>
    <p>{`You may need to change the font for some languages like Arabic to see the translated text.`}</p>
    <h2 {...{
      "id": "get-pseudo-translation"
    }}>{`Get Pseudo Translation`}</h2>
    <p>{`Pseudo-translation is used to create dummy text based on the target language. Both ASCII and
Non-ASCII characters are supported. The dummy text simulates how a translated text may appear inside
a layout based on contraction or expansion due to the translation.`}</p>
    <p>{`Select `}<em parentName="p">{`Plugins->Memsource->get pseudo-translate page…`}</em>{` Choose the characters and the target
language and click translate. The percentage in the second drop-down list indicates the length
change of the translated string. You can also change the bracket that represents the translation to
something else.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "502px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/8579580f8e4c7fdbe9c2d8fd6d7a169b/eea79/memsource_psedudo_config.png",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.83783783783784%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB8klEQVR42p2Sy27TQBSGveItWCPekjUSLCqxYYnYgngCSoVykQKOnCZOfIkv8XVsjx23SdPErX7OjJMSCUojLH06F8/5Z86ZUdRyg5KlYBnDdrvF/35N06CuayjBeofNeoPr1Q1udzs0d/fYNXd77rG+uT2JFVHwCkqnr+KiP0RfHaMz0HDe/SHpDEb41lOxiDPEWYmI8X8i1gQxgzKdL6BNbUxtDxPTxYh8TW9RLw2EaSEXP021F7R9GE6AvLqWFEvBau+vkBRLJHl1xFIWCytiIZbkpfSloB8xzBcxvCCBH6XStj6DF5IlpKXYD9v/AY3BEz7lZatJTp1wmVdi6t92fUymBizHg25YmJo2FcewKD+z5tAudWkF+syCOfcwmsykn5c1iWVSLExI0PYizP2YTpnIk7q0s4gdigVtS9WDfcx/mOHYcKBbLnS6EN3yYHkhRG5suNKKdg6Fj9He8l4wzjhMx6f2AtgkJlrIqyswXiMjkqPCgAqPif52y793OFD+8b4OcL5ESRsKKoIV1ZHokeApiMKfToyuGaBnhehaAcwoQ0bPKiUYEZ4iGLESjGZoU/HLt5/x/M0nvDj7gmevPuL11yFWnGOgaiSW0ng4FDGjp0gJ8c4+9MZ4dzHE++8azs5VdAwPOYksSCxOcyLDL5UiCD3wimz8AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "memsource psedudo config",
          "title": "memsource psedudo config",
          "src": "/static/8579580f8e4c7fdbe9c2d8fd6d7a169b/eea79/memsource_psedudo_config.png",
          "srcSet": ["/static/8579580f8e4c7fdbe9c2d8fd6d7a169b/1efb2/memsource_psedudo_config.png 370w", "/static/8579580f8e4c7fdbe9c2d8fd6d7a169b/eea79/memsource_psedudo_config.png 502w"],
          "sizes": "(max-width: 502px) 100vw, 502px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
  `}</a>{`
    `}</span>
    <p>{`You should be able to see the translated page in the Pages section.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "380px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/db5267673e5362f3f17e9bd025767a5d/3f520/memsource_psedudo_pages.png",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "63.24324324324324%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA1klEQVR42qVT2Q6DIBDk/z/TFw/Ai5tEt84mpD7YpgWScQFhnN1ZxbZt5Jyj8zybkFLiKLquo2EYqHV47zkKPEIIvAHEGJuUiuM4SEpFy7KQUormeW5SygpzcmSdYYXWWjLG0L7vhI9VEQavL4XASuv6RhUh8p4myakCWmsG0s851ylMKbLtMOeOBoUTKxrH8TJI8rrve65lFSEuAmjwApjz1EI/EcIAuIoaYo4IxSD+WyFqdScpsZiDNUqBMuAsxjelAmmB8N7YxW3s4V0hxtlC+OlPeQGDIP1YyDCT/QAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "memsource psedudo pages",
          "title": "memsource psedudo pages",
          "src": "/static/db5267673e5362f3f17e9bd025767a5d/3f520/memsource_psedudo_pages.png",
          "srcSet": ["/static/db5267673e5362f3f17e9bd025767a5d/1efb2/memsource_psedudo_pages.png 370w", "/static/db5267673e5362f3f17e9bd025767a5d/3f520/memsource_psedudo_pages.png 380w"],
          "sizes": "(max-width: 380px) 100vw, 380px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
  `}</a>{`
    `}</span>
    <h2 {...{
      "id": "questionsbug-report"
    }}>{`Questions/Bug report`}</h2>
    <p>{`If you have any questions or find any bugs, please reach out to Ximing Guo (`}<a parentName="p" {...{
        "href": "mailto:ximing.guo@workday.com"
      }}>{`ximing.guo@workday.com`}</a>{`)
or Leo Saguisag (`}<a parentName="p" {...{
        "href": "mailto:leonides.saguisag@workday.com"
      }}>{`leonides.saguisag@workday.com`}</a>{`)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      